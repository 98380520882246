import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { PortableText } from "@portabletext/react"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import // Container,
// Content,
// usePortableTextComponents,
// PopupBanner,
"../components"
import { Container, Content } from "@components/layout"
import { PopupBanner } from "@features"
import { usePortableTextComponents } from "@hooks"

export default function BlogPost({ data }) {
  const [popupOpen, setPopupOpen] = useState(true)

  // console.log("blog-post.js data:", data)
  // const {
  //   slug: { current },
  // } = data.post
  const { post } = data
  // console.log("slug:", current)
  // console.log("data:", data)
  // console.log("post:", post)

  const getPublishedDate = date => {
    const tempDate = new Date(date)
    return tempDate.toLocaleDateString()
  }

  const portableComponents = usePortableTextComponents()

  const {
    allSanityPopup: { edges: popupData },
  } = data
  // console.log("[Blog post template] popupData:", popupData)
  const activePopupExists = popupData.find(popup => popup.node.popupEnabled)
  // console.log("[Blog post template] activePopupExists:", activePopupExists)

  return (
    <Container>
      {activePopupExists && popupOpen && (
        <PopupBanner
          setOpenPopup={setPopupOpen}
          popupData={activePopupExists}
        />
      )}
      <Content>
        <StyledPost>
          <div className="main">
            <Link to="/blog" className="blog-page-link">
              ← Back to the blog
            </Link>
            {/* <div>
          <p>Slug is: {current}</p>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div> */}
            {/* {data.allSanityPost.edges.map((node) => ( */}

            <div className="post">
              {/* {console.log("post.body:", post.body)} */}
              <h1>{post.title}</h1>
              {/* <p>{post.slug.current}</p> */}
              {/* <p>{post.publishedAt}</p> */}
              <p className="published-date">
                {getPublishedDate(post.publishedAt)}
              </p>
              {/* {post.heroImage && <Image {...post.heroImage} alt="two players" />} */}
              {post.heroImage && (
                <SanityImage
                  {...post.heroImage}
                  width={500}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
              <PortableText
                value={post._rawBody}
                components={portableComponents}
              />
              {/* <BlockContent blocks={post.body} /> */}
              {/* {console.log("post.heroImage:", post.heroImage)} */}

              {/* <SanityImage {...post.heroImage} /> */}

              {/* <pre>{JSON.stringify(post, null, 2)}</pre> */}
            </div>
          </div>
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </StyledPost>
      </Content>
    </Container>
  )
}

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      heroImage {
        ...ImageWithPreview
        alt
      }
      title
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 4 })
      _rawSlug
      _type
    }
    allSanityPopup {
      edges {
        node {
          ...SanityPopup
        }
      }
    }
  }
`

const StyledPost = styled.div`
  .main {
    /* margin: 25px; */
    /* width: 750px; */
    width: 100%;
    max-width: calc(100vw - 50px);

    & .published-date {
      margin-top: 0.5rem;
    }

    & .blog-page-link {
      display: inline-block;
      margin-bottom: 3rem;
      text-decoration: none;
      color: hsl(220, 48%, 20%);
      opacity: 0.7;

      &:hover {
        color: hsl(195, 100%, 40%);
        opacity: 1;
      }
    }
  }

  p {
    /* background: rebeccapurple;
    line-height: 45px; */
    margin: 0.5em 0 1em;
  }

  ol,
  ul,
  menu {
    list-style: disc;
  }
`
